import baseURL from "@/utils/config";
import request from "@/plugins/axios";
export function queryGoodsList(data,langEnv=2) {
  return request({
    url: `${baseURL.javaApi}/api/points-product-list`,
    method: "post",
    data,
    // headers:{
    //     appLangEnv:langEnv
    //   }
  });
}
export function queryPointsOrderList(data,langEnv=2) {
    return request({
        url: `${baseURL.javaApi}/authapi/points-order-list`,
        method: "post",
        data,
      //   headers:{
      //   appLangEnv:langEnv
      // }
    });
}
export function queryDetail(data,langEnv=2) {
    return request({
        url: `${baseURL.javaApi}/api/points-product-detail`,
        method: "post",
        data,
      //   headers:{
      //   appLangEnv:langEnv
      // }
    });
}
export function createOrder(data,langEnv=2) {
    return request({
        url: `${baseURL.javaApi}/authapi/points-order`,
        method: "post",
        data,
      //   headers:{
      //   appLangEnv:langEnv
      // }
    });
}
export function queryPointsList(data,langEnv=2) {
    return request({
        url: `${baseURL.api}/authapi/user/searchUserPointsHistory`,
        method: "post",
        data,
      //   headers:{
      //   appLangEnv:langEnv
      // }
    });
}