<template>
  <div class="record-box">
    <div class="top">
      <nav-bar
        :isFixed="true"
        :goBack="goBack"
        :title="$t('我的積分')"
        :bgc="'background-color: #f8f8f8;'"
        v-if="isMobile()"
      ></nav-bar>
      <div class="nav" v-else>
        <img
          class="img"
          @click="gotoBack"
          src="@/assets/icons/Arrow_left.png"
          alt=""
        />
        <div class="title">{{ $t("我的積分") }}</div>
      </div>
      <div class="point-box">
        <div class="point-label">{{ $t("可用積分") }}</div>
        <div class="point-num">{{ availablePoints }}</div>
      </div>
    </div>
    <div class="tab-box">
      <van-tabs
        class="van-tabs-fixed"
        sticky
        color="#71B800"
        background="#f7f9fa"
        title-active-color="#71B800"
        v-model="activeTab"
        @change="handleChangeTab"
      >
        <van-tab
          v-for="tab in tabList"
          :key="tab.key"
          :name="tab.key"
          :title="tab.title"
          title-class="tab-item"
        />
      </van-tabs>
    </div>
    <div class="content-box" @scroll="handleScroll($event)">
      <template v-if="list.length > 0">
        <div class="row-item" v-for="(item,index) in list" :key="item.orderNo+index+Date.now()">
          <img
            class="img"
            :src="item.picturePath?getPointsImgUrl(item.picturePath).replace('{0}', '400x400'):defaultImage || defaultImage"
            @error="imgOnError($event)"
            alt=""
          />
          <div class="info-right">
            <div class="right-row">
              <div class="product-name">{{ item.productName }}</div>
              <div class="date">{{ item.usedTime }}</div>
            </div>
            <div class="right-row">
              <div class="order-no">{{ item.orderNo }}</div>
              <div
                class="points"
                :style="{ color: item.status === 0 ? '#90d12e' : '#f23030' }"
              >
                {{ item.usedPoints>0?'+'+item.usedPoints:item.usedPoints }}
              </div>
            </div>
            <div
              class="tag"
              :style="{
                background: item.usedPoints> 0 ? '#90d12e' : '#f23030',
              }"
            >
              {{ item.tag }}
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="orderempty">
          <img src="@/assets/image/order/orderempty.png" alt="" />
          <div class="noOrder">{{ $t("空空如也") }}~</div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { getPointsImgUrl } from "@/utils/util";
import { queryPointsList,queryGoodsList } from "@/api/pointsMall";
export default {
  data() {
    return {
      availablePoints: 0,
      activeTab: "total",
      tabList: [
        { key: "total", title: this.$t("全部") },
        { key: "plus", title: this.$t("收入") },
        { key: "reduce", title: this.$t("支出") },
      ],
      list: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      defaultImage: require("@/assets/image/default.png"),
    };
  },
  mounted() {
    this.getMyPoints();
    this.getList();
  },
  methods: {
    getPointsImgUrl,
    isMobile() {
      var u = navigator.userAgent;
      const isAndroid =
        (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) &&
        u.indexOf("MMMMessenger") > -1; //android终端
      const isIos =
        !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) &&
        u.indexOf("MMMMessenger") > -1; //ios终端
      return isAndroid || isIos;
    },
    gotoBack() {
      this.$router.go(-1);
    },
    handleChangeTab(val) {
      if (val !== this.activeTab) {
        this.activeTab = val;
        this.pageIndex = 1;
        this.getList();
      }
    },
    imgOnError(e) {
      e.target.src = this.defaultImage;
    },
    async getList(flag) {
      this.loading = true;
       const langEnv = this.$route.query.langEnv;
      const res = await queryPointsList({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        type: this.activeTab,
        langEnv: langEnv?langEnv:window.localStorage.getItem("langEnv"),
      },langEnv);
      if (res.resultID === 1200) {
        this.loading = false;
        this.list = flag?[...this.list,...res.data.list]:res.data.list;
        this.total = res.data.totalPage;
      }
    },
    handleScroll(e) {
      if (
        e.target.scrollTop + e.target.clientHeight + 1 >=
          e.target.scrollHeight &&
        !this.loading
      ) {
        if (this.pageIndex * this.pageIndex < this.total) {
          this.pageIndex += 1;
          this.getList(true);
        }
      }
    },
    async getMyPoints(){
       const langEnv = this.$route.query.langEnv;
      const res = await queryGoodsList({
        langEnv: langEnv?langEnv:window.localStorage.getItem("langEnv"),
      },langEnv);
      if (res.resultID === 1200) {
        this.availablePoints = res.data.points;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.record-box {
  height: 100vh;
  .top {
    width: 100%;
    height: 210px;
    background: #90d12e;
    position: relative;
    .nav {
      width: 100%;
      height: 44px;
      line-height: 44px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #1a1a1a;
      text-align: center;
      position: relative;
      .img {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 14px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .point-box {
      position: absolute;
      bottom: 30px;
      left: 30px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #fff;
      line-height: 20px;
      .point-label {
        margin-bottom: 20px;
      }
    }
  }
  .content-box {
    height: calc(100vh - 260px);
    overflow: auto;
    overflow-x: hidden;
    width: 100vw;
    .row-item {
      width: 100vw;
      display: flex;
      align-items: flex-end;
      padding: 12px 16px;
      background: #fff;
      border-bottom: 1px solid #eee;
      .img {
        width: 60px;
        height: 60px;
        margin-right: 20px;
      }
      .info-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: calc(100% - 80px);
        .right-row {
          line-height: 1;
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          .product-name {
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 14px;
            color: #333;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 隐藏溢出内容 */
            text-overflow: ellipsis; /* 溢出时显示省略号 */
          }
          .order-no {
            font-size: 12px;
            color: rgb(153, 153, 153);
          }
          .points {
            font-size: 16px;
            color: #90d12e;
          }
          .date {
            width: fit-content;
            font-size: 12px;
            color: #999;
          }
        }
        .tag {
          font-size: 12px;
          color: #fff;
          font-size: 12px;
          padding: 4px;
          border-radius: 5px;
          line-height: 1;
          width: fit-content;
        }
      }
    }
    .orderempty {
      padding-top: 200px;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      flex-direction: column;
      text-align: center;
      color: #1a1a1a;
      img {
        width: 140px;
        height: 140px;
        object-fit: contain;
      }
      .noOrder {
        width: 140px;
      }
    }
  }
}
</style>
